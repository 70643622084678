define(['app', 'siteObj'], (app, siteObj) => {

  const wishlist = () => {
    const component = {};

    const _channels = {
      update: 'responsiveProductListPage/update'
    };

    const _init = (element) => {
      component.element = element;
      app.subscribe(_channels.update, component.sortWishlist);
      return component;
    };

    const _sortWishlist = (value) => {
      window.location.href = siteObj.siteURL + 'wishlists.account?sortBy=' +  Object.values(value);
    };

    component.init = _init;
    component.sortWishlist = _sortWishlist;

    return component;
  };

  return wishlist;
});
